<template>
  <!-- tab内容列表，传文件类型修改背景 -->
  <div class="item"
       v-for="(item,index) in list"
       :key="index"
       @click='doDetail(item)'
       :style="{'background-image':backgColor[item.subject_id].background[type]}">
    <div class="text"
         :style="type=='document'||type=='image'?{'color':'#fff'}:{'color':backgColor[item.subject_id].textcolor}">

      <div class="desc">
        {{item.subject_cn}}
      </div>
      <div class="filename">
        {{item.filename}}
      </div>
      <div class="info">
        <div>{{ item.user_name }}</div>
        <div>{{ item.created_time }}</div>
      </div>
    </div>
  </div>
  <div></div>
</template>


<script setup>
import { backgColorProperty } from './colorProperty.js'
import { onMounted } from 'vue'

let backgColor = backgColorProperty
const { list, type } = defineProps({
  list: {
    type: Array,
    default: [{ subject_id: null, subject_name: '' }]
  },
  type: {
    type: String
  }
})
let emit = defineEmits(['handleClickResource'])
const doDetail = (item) => {
  emit('handleClickResource', item)
}
onMounted(() => {
})
</script>

<style lang='scss' scoped>
.item {
  background-size: 100% 100%;
  position: relative;
  height: 330rem;
  width: 300rem;
  margin-right: 64rem;
  margin-top: 30rem;
  border-radius: 10rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  .text {
    text-align: center;
    font-size: 24rem;
    margin-top: 17rem;
    font-weight: bold;
    line-height: 33rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .filename {
      width: 208rem;
      font-size: 20rem;
      font-weight: 500;
      color: #333333;
      line-height: 30rem;
      /* white-space: normal; */
      word-wrap: break-word;
      margin-top: 136rem;
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 16rem;
      width: 100%;
      padding: 0 11rem;
      font-size: 20rem;
      color: #fff;
    }
  }
}
</style>