<template>
  <!-- tab页 -->
  <div class='tabs-wrap'>
    <div class="card-container">
      <a-tabs v-model:activeKey="activeKey"
              type="card"
              @change='handleToggleResource'>
        <a-tab-pane :key="item.value"
                    :tab="item.name"
                    v-for='(item) in resourceTypeList'>
          <div class="wrap">
            <div class='items'
                 v-if='resourceList.length>0'>
              <Item :list='resourceList'
                    :type='item.value'
                    @handleClickResource='handleClickResource' />
            </div>
            <div v-else
                 style='padding-left:51rem'> 暂无数据</div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <!-- 图片预览 -->
      <a-image :width="200"
               :style="{ display: 'none' }"
               :preview="{
                  visible:IsShowImageViewer,
                  onVisibleChange: setVisible, 
                 }"
               :src="imageUrl" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from '@vue/runtime-core'
import { backgColorProperty } from './colorProperty.js'
import Item from './item.vue'
import { useRouter } from 'vue-router'
import { instance } from '@/utils/http.js'
let activeKey = ref('document')
let backgColor = backgColorProperty
let resourceList = ref([])
let resourceTypeCurrentValue = ref('video')
let resourceTypeList = ref([])
let router = useRouter()
let IsShowImageViewer = ref(false)//图片显示
let imageUrl = ref('')//显示的图片地址

//获取文件类型列表
const getResourceType = async () => {
  const { data: resourceTypeData } = await instance({
    url: '/api/v1/teaching/type',
    method: 'get'
  });
  resourceTypeList.value = resourceTypeData.list;
}
//切换文件类型获取资源列表
const handleToggleResource = async (value) => {
  resourceList.value = []
  resourceTypeCurrentValue.value = value;
  const type = value;
  // 请求资源列表
  const { data: resourceData } = await instance({
    url: '/api/v1/teaching/file_lst',
    method: 'get',
    params: { type }
  });
  resourceList.value = resourceData.list.map(item => {
    // 处理日期,仅显示年月 日
    item.created_time = item.created_time.split(' ')[0];
    return item;
  });
  // 小处理一下，将文件的类型转变成引文字符串保存
  // 后面接口优化可能就删除了，所以有预知先写到外面，性能差就差点
  /* const resourceTypeListObj = {};
  resourceTypeList.value.map(item => {
    resourceTypeListObj[item.name] = item.value;
  });
  resourceList.value.map(item => {
    item.type_value = resourceTypeListObj[item.type_name];
  });
  resourceList.value = [...resourceList.value] */

  // 结束
}
const handleClickResource = (item) => {
  switch (item.type_name) {
    case '图片':
      setVisible(true)
      imageUrl.value = item.url;

      break;
    case 'PDF':
      window.open(item.url)
      break;
    default:
      router.push('/course/preview?type=video&url=' + item.url + "&user_name=" + item.user_name + '&subject_cn=' + item.subject_cn + '&created_time=' + item.created_time)
      //this.navigateTo('/course/preview', { type: item.type_value, url: item.url });
      break;
  }
}
//控制图片预览是否显示
const setVisible = (bool) => {
  IsShowImageViewer.value = bool
}


onMounted(() => {
  handleToggleResource(activeKey.value)
  getResourceType()

})
</script>

<style lang='scss' scoped>
.tabs-wrap {
  :deep .ant-tabs {
    font-size: 32rem;
    color: #ccdcff;
    // font-family: PingFang-SC-Heavy, PingFang-SC;
  }
  :deep .ant-tabs-tab:nth-last-child(2) .ant-tabs-tab-btn:before {
    display: none;
  }
  :deep .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 30rem;
    display: block;
    margin: 0 auto;
    &:before {
      content: "";
      width: 1rem;
      height: 40rem;
      background: #ccdcff;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -20rem;
    }
  }

  :deep .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: #fff;
  }

  .card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  :deep .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    font-weight: 800;
    font-size: 34rem;

    &:after {
      content: "";
      width: 70rem;
      height: 10rem;
      background: #ff9399;
      position: absolute;
      bottom: 0;
      border-radius: 5rem;
      left: 50%;
      margin-left: -35rem;
    }
  }
  :deep .card-container > .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: transparent;
    // padding: 25rem 57rem;
    width: 167rem;
    text-align: center;
    border: 0;
  }
  :deep .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid transparent;
    content: "";
  }

  .wrap {
    width: 1480rem;
    height: 360rem;
    overflow-y: scroll;
  }
  .items {
    display: flex;
    -webkit-transition: all 1s;
    flex-wrap: wrap;
    width: 100%;
    transition: all 1s;
    padding-left: 10rem;
    // font-family: PingFang-SC-Bold, PingFang-SC;
  }
}
</style>